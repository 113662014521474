import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';

import { HomeComponent } from './components/home/home.component';
import { CreateEventComponent } from './components/create-event/create-event.component';
import { ViewEventComponent } from './components/view-event/view-event.component';
import { JoinEventComponent } from './components/join-event/join-event.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpModule,
    HttpClientModule,

    MatCardModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatListModule,
    MatChipsModule,
    MatInputModule,
    MatToolbarModule,
    MatSnackBarModule,

    RouterModule.forRoot([
      { path: '', component: HomeComponent },
      { path: 'create-event', component: CreateEventComponent },
      { path: 'view-event/:id', component: ViewEventComponent },
      { path: 'view-event', component: ViewEventComponent },
      { path: 'join-event', component: JoinEventComponent }
    ])
  ],
  declarations: [
    HomeComponent,
    CreateEventComponent,
    ViewEventComponent,
    JoinEventComponent
  ],
  providers: [
    
  ]
})
export class SharedModule {
  /* make sure SharedModule is imported only by one NgModule the AppModule */
  constructor (
    @Optional() @SkipSelf() parentModule: SharedModule
  ) {
    if (parentModule) {
      throw new Error('SharedModule is already loaded. Import only in AppModule');
    }
  }
}
