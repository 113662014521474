import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

import { AuthService } from '@app/security';
import { EventService } from '@app/core';
import { IUser } from '@app/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: IUser;
  userId: string;
  delay: boolean = true;
  eventCollection;
  joinedCollection;

  constructor(
    private authService: AuthService,
    private angularFireAuth: AngularFireAuth,
    private router: Router,
    private eventService: EventService
  ) {
    this.angularFireAuth.auth.getRedirectResult()
    .then(result => {
      if (result.user) {
        this.authService.updateUserData(result.user);
      }
      this.authService.user.subscribe(u => this.user = u);
    });

    setTimeout(() => {
      this.timeDelay();
    }, 2000);
  }

  ngOnInit() {
    this.authService.user.subscribe(u => this.user = u);

    let returnUrl = localStorage.getItem('returnUrl');

    if (!returnUrl) return;

    localStorage.removeItem('returnUrl');
    this.router.navigateByUrl(returnUrl);
  }

  login() {
    this.authService.login();
  }

  logout() {
    this.authService.logout();
  }

  timeDelay() {
    this.delay = false;
    this.userId = this.user.uid;
    this.eventCollection = this.eventService.getEvents(this.userId);
    this.joinedCollection = this.eventService.getYourEvents(this.user);
  }
}
