import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from './services/auth.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [

  ],
  providers: [
    AuthService
  ]
})
export class SecurityModule {
  /* make sure SecurityModule is imported only by one NgModule the AppModule */
  constructor (
    @Optional() @SkipSelf() parentModule: SecurityModule
  ) {
    if (parentModule) {
      throw new Error('SecurityModule is already loaded. Import only in AppModule');
    }
  }
}
