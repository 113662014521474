import { Component, OnInit } from '@angular/core';
import { IUser, EventService } from '@app/core';
import { AuthService } from '@app/security';
import { Router } from '@angular/router';

@Component({
  selector: 'app-join-event',
  templateUrl: './join-event.component.html',
  styleUrls: ['./join-event.component.scss']
})
export class JoinEventComponent implements OnInit {
  user: IUser;

  constructor(
    private eventService: EventService,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.user.subscribe(u => this.user = u);
  }

  ngOnInit() {
  }

  joinEvent(event) {
    this.eventService.setAttendee(event, this.user);
    this.router.navigate(['']);
  }
}
