import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { IUser, IEvent, IItem } from '@app/core';
import { firestore } from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  constructor(
    private angularFirestore: AngularFirestore
  ) { }

  getEvents(userId) {
    return this.angularFirestore.collection('events', ref => ref.where('owner', '==', userId))
    .snapshotChanges()
    .pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      })
    }));
  }

  getYourEvents(user) {
    return this.angularFirestore.collection('events', ref => ref.where('attendees', 'array-contains', user))
    .snapshotChanges()
    .pipe(map(actions => {
      return actions.map(a => {
        const data = a.payload.doc.data();
        const id = a.payload.doc.id;
        return { id, ...data };
      })
    }));
  }

  getEvent(event) {
    return this.angularFirestore.collection('events').doc(event)
    .snapshotChanges()
    .pipe(map(actions => {
      const data = actions.payload.data();
        const id = actions.payload.id;
        return { id, ...data };
    }));
  }

  setEvent(event, owner) {
    event.owner = owner;
    event.attendees = [];
    return this.angularFirestore.collection('events').add(event);
  }

  setAttendee(event, user) {

    const data: IUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL
    };

    return this.angularFirestore.collection('events').doc(event.eventCode).update({ attendees: firestore.FieldValue.arrayUnion(user) });
  }
}
