import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, UserService, IEvent, IUser } from '@app/core';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.scss']
})
export class CreateEventComponent implements OnInit {
  ownerId;

  constructor(
    private eventService: EventService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService
  ) {
    this.ownerId = this.route.snapshot.queryParamMap.get('id');
  }

  ngOnInit() {
  }

  saveEvent(event: IEvent) {
    this.eventService.setEvent(event, this.ownerId);
    this.router.navigate(['/']);
  }
}
