import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private angularFirestore: AngularFirestore
  ) { }

  getUser(userId) {
    return this.angularFirestore.collection('users').doc(userId).valueChanges();
  }
}
