import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService, UserService } from '@app/core';
import { IEvent } from '@app/core';
import { map } from 'rxjs/operators';
import { AuthService } from '@app/security';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.component.html',
  styleUrls: ['./view-event.component.scss']
})
export class ViewEventComponent implements OnInit {
  event;
  eventId;
  owner;

  constructor(
    private eventService: EventService,
    private userService: UserService,
    private authService: AuthService,
    public snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.eventId = this.route.snapshot.queryParamMap.get('id');
    this.eventService.getEvent(this.eventId).subscribe(e => {
      this.event = e;
    });

    this.authService.user.subscribe(u => this.owner = u);
  }

  ngOnInit() {
  }

  share(event) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (event));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');

    this.snackBar.open('Event code has been copied!', '', {
      duration: 3000,
    });
  }
}
